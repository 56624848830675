@import "../../style/mixins";

.terms {
  padding: 48px 0 48px !important;
  background-color: #fff;
  @include text-subtitle;

  .container {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.ltr li img {
    margin-right: 16px;
  }

  &.rtl li img {
    margin-left: 16px;
  }

  .title {
    @include title-h3;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.3em;
  }

  ul {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      margin-top: 14px;
      display: flex;
      align-items: center;
      flex: 0 0 48%;
      font-size: 16px;
      line-height: 1.17;

      img{
        width: 24px;
        height: 24px;
      }
    }
  }
}

@include break-point(mobile) {
  .terms {
    font-size: 5vw;
    padding: 48px 0 !important;

    .title {
      font-size: 4.1vw;
    }

    ul {
      li {
        flex: 0 0 100%;
      }
    }
  }
}