@import "../../style/mixins";

.toggle-lang {
  display: flex;
  align-items: center;
  height: 100%;

  li {
    display: none;
    margin-left: 16px;
    @include text-small;
    font-family: "HyundaiMedium", "Arial", "sans-serif";
    font-weight: 500;
    color: #666666;
    cursor: pointer;
    transition: 0.25s;

    &.active {
      color: #000000;
      cursor: default;
    }

    &:hover {
      color: #000000;
    }

    &:first-child {
      margin: 0;
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 20px;
    height: 4px;
    transition: 0.26s;
    background-color: $primary-color;
  }

  &.active {
    li {
      margin-left: 0;
      margin-right: 16px;
    }
  }

  &--one-string {
    li.active {
      display: list-item;
      cursor: pointer;
      font-weight: 700;
    }

    &::after {
      content: none;
    }
  }
}
