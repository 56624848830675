@import "../../style/mixins";

.pp-section-slider-wide-cards {
//   padding-top: 144px;
    .wrapper {
      display: flex;
      flex-direction: row;
      .info-block {
        flex: inherit;
        width: 299px;
        padding: 75px 32px 32px 32px;
        background-color: #F7F3F2;
        .text-block {
          width: 100%;
        }
      }
      .image {
        flex: 1 1;
        img{
          height: 500px;
        }
      }

      @media (max-width: 1023px) {
        .info-block {
          flex: 1 0;
        }

        .image {
          flex: 0 1;
        }
      }
    }
  .swiper-slide {
    transition: opacity .3s ease;
  }
  .swiper-slide-duplicate.swiper-slide-prev, .swiper-slide-prev {
    opacity: 0;
  }
  .slider {
    margin-top: 8px;
  }
  .swiper-pagination {
    text-align: right;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    opacity: 0.5;
    margin-top: 21px;
  }
}
@media screen and (max-width: 1023px) {
  .pp-section-slider-wide-cards {
    .wrapper {
      flex-direction: column;
    }
    .wrapper .info-block {
      width: auto;
      padding: 24px 40px 40px 24px;
      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 36px;
        width: 80%;
      }
    }
    .pagination .swiper-pagination {
      justify-content: right;
    }
  }
}


@include break-point(mobile) {
  .pp-section-slider-wide-cards {
    padding-top: 0;
    .wrapper .image img {
        height: 385px;
    }
    .pagination .swiper-pagination {
      justify-content: right;
    }
  }
}
