@import "./src/style/mixins";

.description-block {
  &.desktop {
    position: absolute;
    bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    width: 71%;
    left: 50%;
    min-height: 100px;
    transform: translateX(-50%);
    .item {
      opacity: 0;
      &:not(:last-of-type){
        margin-right: 16px;
      }
      &.rtl {
        &:not(:last-of-type){
          margin-right: 0;
          margin-left: 16px;
        }
      }
    }
  }
  &.white {
    .item {
      color: #fff;
      ul {
        li {
          &:not(:first-of-type) {
            &:before {
              background: #fff;
            }
          }
        }
      }
    }
  }
  .item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    @include HyundaiRegular(16px, 24px, normal, normal, #000);
    &:not(:last-of-type){
      margin-bottom: 20px;
    }
    text-align: left;
    ul {
      li {
        &:not(:first-of-type) {
          box-sizing: border-box;
          padding-left: 14px;

          &:before {
            content: "";
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #1385B7;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
    &.anim {
      animation: fadeIn 0.24s 1;
      animation-fill-mode: forwards;
    }
    &.reverse-anim {
        animation: fadeOut 0.06s 1;
        animation-fill-mode: forwards;
    }

    &.rtl {
        text-align: right;

      svg {
        margin-right: 0;
        margin-left: 16px;
      }
      ul {
        li {
          text-align: right;
          &:not(:first-of-type) {
            padding-right: 14px;
            padding-left: 0;

            &:before {
              right: 0;
              left: unset;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }
  svg {
    width: 24px;
    height: 24px;
    margin-right: 16px;
    flex-shrink: 0;
  }



}
@include break-point(desktop-small) {
  .description-block {
    &.desktop {
      width: 95%;
      bottom: 8px;
    }
  }
}
@include break-point(desktop-tiny) {
  .description-block {
    &.desktop {
      width: 98%;
      .item {
        font-size: 14px;
        line-height: 18px;
      }
      svg {
        width: 18px;
        height: 18px;
        margin-right: 16px;
        flex-shrink: 0;
      }
    }
  }
}
@include break-point(tablet){

}
@include break-point(mobile){
  .description-block {
    min-height: 230px;
  }
}
@keyframes fadeIn {
  0%{
    opacity: 0;
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeOut {
  0%{
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(20%);
  }
}