@import "../../../style/mixins";

.control-arrow {
  width: 48px;
  height: 48px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 6px 12px rgba(45, 49, 54, 0.2);
  transform: translateX(50%);
  transition: 0.16s;
  cursor: pointer;
  z-index: 5;

  svg {
    @include positionCC;
    left: 52%;
    height: 16px;
    width: 9px;
    stroke: #000000;
    transition: 0.26s;
  }

  &:hover {
    box-shadow: 0 8px 16px rgba(45, 49, 54, 0.2);

    svg {
      stroke: $primary-color;
    }
  }

  &.reversed {
    transform: translateX(-50%);

    svg {
      left: 48%;
    }
  }

  &.disabled {
    opacity: 0;
    cursor: default;

    svg {
      stroke: #000000;
    }
  }

  &.single {
    @include positionY;
    transform: translateX(50%) translateY(-50%);
    right: 0;

    &.reversed {
      left: 0;
      right: auto;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  &.color-button-prev,
  &.color-button-next {
    width: 12px;
    height: 22px;
    box-shadow: none;
    background-color: transparent;
    border-radius: 0;
    transform: translateY(-50%);
    top: 30%;

    svg {
      transform: none;
      top: 0;
      left: 0;
      width: 12px;
      height: 22px;
    }
  }

  &.color-button-prev.reversed {
    left: -24px;
  }

  &.color-button-next {
    left: auto;
    right: -24px;
  }
}

.controls {
  @include positionY;
  top: 50%;
  width: 100%;
  z-index: 5;
  display: flex;
  justify-content: space-between;

  &.duo {
    top: -48px;
    right: 0;
    transform: translateY(-100%);
    width: auto;

    .control-arrow {
      transform: none;
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }

      &.disabled {
        opacity: 0.5;
      }
    }
  }
  &.rtl {
    .control-arrow {
      box-shadow: 0 -6px 12px rgba(45, 49, 54, 0.2);
      transform: translate(-50%);

      &.swiper-button-prev {
        transform: translate(50%);
      }

      svg {
        transform: translateX(-50%) translateY(-50%) rotate(180deg);
      }

      &:hover {
        box-shadow: 0 -8px 16px rgba(45, 49, 54, 0.2);
      }
    }
  }
  @media screen and (max-width: 1023px) {
    width: 95%;
    left: 2.5%;
    top: 50%;
  }
}

.slim .controls {
  top: 50%
}

// @include break-point(mobile) {
//   .controls {
//     display: none;
//   }

//   .control-arrow {
//     display: none;
//   }
// }
