.image {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  &.tinted {
    &:after {
      content: "";
      width: 100%;
      height: 40%;
      background: linear-gradient(0deg, rgba(0,0,0,0.9), rgba(0,0,0,0));
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
}
