@import "../../style/mixins";

.card-img {
    // height: 800px;
    &.rtl {
        .img-wrapper {
            left: 0;
            right: inherit;
        }
        .info-wrapper--reversed .info-wrapper__body {
            margin-right: 60px;
            margin-left: 0px;
        }
    }
    .info-wrapper {
        position: initial;
        &--reversed{
            display: flex;
            flex-direction: row-reverse;
            .info-wrapper__body {
                margin-left: 60px;
            }
        }
    }
    .info-wrapper__body {
        margin-top: 0;
        width: 34%;
    }
    .title {
        margin-bottom: 38px;
        @include title-h3;
        // font-style: normal;
        // font-weight: 600;
        // font-size: 30px;
        // line-height: 35px;
        color: #000000;
    }
    .subtitle {
        margin-bottom: 38px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: #000000;
    }
    .description {
        @include text-simple;
        // font-style: normal;
        // font-weight: 400;
        // font-size: 16px;
        // line-height: 26px;
        color: #000000;
        width: 66%;
        margin-bottom: 20px;
    }
    .subtitle + .description {
        width: 100%;
    }
    .img-wrapper {
        right: 0;
        position: absolute;
        top: 0;
        width: 100%;
        max-width: 50%;
        height: 100%;
        img {
            object-fit: contain;
        }
        .swiper-slide {
            height: initial;

            .info-block {
                display: none;
            }
        }
    }
    .img-wrapper--small {
        max-width: calc(100% - 34px - 34%);
        .swiper-slide {
            height: 500px;
            width: 100%;

            .info-block {
                display: none;
            }
        }
    }

    .controls {
        width: 90%;
        left: 5%;
        top: 50%;

        @media screen and (max-width: 1023px) {
            width: 80%;
            left: 10%;
            top: 50%;
        }
    }

    img {
        width: 100%;
        height: 100%;
    }

    @media screen and (max-width: 1710px) {
        .info-wrapper__body {
            margin-top: 0px;
        }
        img {
            width: 100%;
            height: 100%;
        }
        .img-wrapper {
            // top: 96px;
            top: 0;
        }
    }
    @media screen and (max-width: 1180px) {
        height: 100%;
        // min-height: 600px;

        img {
            height: 100%;
        }
        .title {
            font-size: 28px;
            line-height: 36px;
            margin-bottom: 12px;
        }
        .description {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            width: 87%;
        }
        .info-wrapper__body{
            width: 40%;
        }
    }
    @media screen and (max-width: 1023px) {
        height: 100%;
        .img-wrapper {
            top: 0px;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }
    @media screen and (max-width: 767px) {
        height: 100%;
        &--reversed {
            display: flex;
            flex-direction: column-reverse;
            .info-wrapper__body {
                margin-top: 18px;
                .subtitle {
                    margin-bottom:8px;
                }
            }
        }
        .info-wrapper--reversed {
            flex-direction: column;
            .info-wrapper__body {
                margin-left: 0;
                margin-bottom: 32px;
            }
        }
        .title {
            width: 60%;
        }
        .description {
            // width: 66%;
            font-size: 13px;
        }
        .img-wrapper {
            position: inherit;
            padding: 0 16px;
            width: auto;
            max-width: 100%;
        }
        .img-wrapper--small {
            max-width: 100%;
        }
        .info-wrapper__body {
            width: auto;
        }

        img {
            width:100%;
            height: 100%;
        }
    }
}
