@import "../../../../style/mixins";

.picture-slide {
  max-height: 789px;

  .counter {
    position: absolute;
    z-index: 2;
    bottom: 40px;
    right: 40px;
    background: transparent;
  }

  &.white-counter {
    .counter {
      background: transparent;
      color: #fff;
    }
  }

  &.black-counter {
    .counter {
      background: transparent;
      color: #000;
    }
  }

}


@include break-point(mobile) {
  .picture-slide {

    .counter {
      bottom: 10px;
      right: 10px;
    }
  }
}
