@import "../../style/mixins";

.pp-section {

  &.move-car {
    padding-bottom: 70px;
    &.rtl {
      .image-wrapper {
        transform: scale(-1, 1);
      }
    }

    .container {
      text-align: center;

      .title-block {
        margin: 44px auto 48px;
        max-width: 60%;

        .title {
          margin: 0 auto;
          max-width: 900px;
          font-size: 74px;
          line-height: 74px;
          text-align: center;
        }

        .subtitle {
          width: 100%;
          max-width: 100%;
          font-weight: 400;
          font-size: 74px;
          line-height: 74px;
          text-align: center;
          color: #E7E1D8;
        }
      }
      .sonata-move {
          img {
            height: auto;
            width: 1300px;
          }
      }
      .image-wrapper {
        height: 450px;
      }
      .move-car__buttons {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .move-car__button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 166px;
        padding: 10px 20px;
        background-color: #E4DCD3;
        cursor: pointer;
        font-family: "HyundaiRegular", "Arial", "sans-serif";
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.02em;

        &--active {
          background-color: $dark-color;
          color: #FFF;
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    &.move-car {
      .container {
        .title-block {
          .title {
            font-size: 74px;
            line-height: 74px;
          }
          .subtitle {
            font-size: 74px;
            line-height: 74px;
          }
        }
        .sonata-move img {
          height: auto;
          width: 1700px;
        }
        .image-wrapper {
          height: 400px;
        }
      }
    }
  }
  @media screen and (max-width: 1023px) {
    &.move-car {
      .container {
        .title-block {
          .title {
            font-size: 47px;
            line-height: 58px;
          }
          .subtitle {
            font-size: 74px;
            line-height: 74px;
          }
        }
        .sonata-move img {
          height: auto;
          width: 1200px;
        }
        .image-wrapper {
          height: 250px;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    &.move-car {
      .container {
        .title-block {
          .title {
            font-size: 36px;
            line-height: 39px;
          }
          .subtitle {
            font-size: 60px;
            line-height: 60px;
          }
        }
        .sonata-move img {
          height: auto;
          width: 900px;
        }
        .image-wrapper {
          height: 250px;
        }
      }
    }
  }
}
