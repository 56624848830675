@import "../../../../style/mixins";


.default-slide {

  .wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  // .image {
    // flex: 0;
  // }

  .info-block {
    background-color: #EBEDF3;
    padding: 40px;
    flex: 1;

    .text-block {
      margin-top: 16px;
    }

  }

  &.small {
    .info-block {
      background-color: transparent;
      padding: 32px 0 5px;
    }
  }

  &.white {
    .info-block {
      .text-block {
        .title,
        .subtitle {
          color: #ffffff;
        }
      }
    }
  }

  &.fill-white {
    .info-block {
      background-color: #ffffff;
    }
  }

}

@include break-point(mobile) {

  .default-slide {

    .info-block {
      padding: 24px 16px;

      .counter {
        padding: 4px 11px;
      }

      // .text-block {

        //.subtitle {
        //  font-size: 4.37vw;
        //  line-height: 1.29;
        //}
      // }

    }

  }

}
