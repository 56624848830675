@import "../../style/mixins";

.pp-section-slider-cards {
  padding-top: 144px;

  &--combination {
    .title-block {
      .title {
        font-size: 50px;
        line-height: 56px;

        @media (max-width: 768px) {
          font-size: 32px;
          line-height: 1.1;
        }
      }
    }

    .slider {
      .controls {
        top: 32%;
      }

      .default-slide {
        .image {
          height: 250px;

          img {
            height: 100%;
          }
        }
      }
    }
  }

  &--smart-sense {
    .title-block {
      .title {
        font-size: 50px;
        line-height: 56px;

        @media (max-width: 768px) {
          font-size: 32px;
          line-height: 1.1;
        }
      }
    }

    .slider {
      .controls {
        top: 18%;
      }
      .text-block {
        .title {
          font-size: 28px;
        }
      }
      .image {
        height: auto;
      }
    }
  }

  .swiper-slide {
    transition: opacity .3s ease;
  }
  .swiper-slide-duplicate.swiper-slide-prev, .swiper-slide-prev {
    opacity: 0;
  }
  .slider {
    margin-top: 40px;
  }

  .image-scroll {
    position: absolute;
    top: 0;
    transform: translateY(-50%);

    .image {
      height: 100px;
    }
  }
  .default-slide .info-block .text-block {
    width: 70%;
  }
  .swiper-pagination {
    text-align: right;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    opacity: 0.5;
    margin-top: 21px;
  }

  .pagination.dynamic .pagination-container {
    bottom: -50px;
    right: 0;
  }
}
@media (min-width: 1920px) {
  .pp-section-slider-cards .container:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: white;
    bottom: 0;
    left: -100%;
    z-index: 2;
  }
}
@media screen and (max-width: 1023px) {
  .pp-section-slider-cards {
    // .title-block  {
    //   width: 58%
    // }
    .pagination .swiper-pagination {
      justify-content: right;
    }
  }
}


@include break-point(mobile) {
  .pp-section-slider-cards {
    padding-top: 0;
    .slider {
      margin-top: 40px;
    }

  }
}
