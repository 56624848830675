@import "../../../style/mixins";

.text-block {
  max-width: 800px;
  &.container {
    max-width: 1260px;
    margin-top: 56px;
    .subtitle {
      max-width: 600px;
    }
  }

  .title {
    @include title-h3;
    span {
      color: #00AAD2;
    }
  }

  h4.title {
    @include text-simple;
    font-family: "HyundaiMedium", "Arial", "sans-serif";
    font-weight: 500;
  }

  .subtitle {
    @include text-simple;
    margin-top: 12px;
    max-width: 760px;

    @media screen and (max-width: 1180px) {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
    }

    @include break-point(mobile) {
      //font-size: 4.3vw;
      font-size: 13px;
      line-height: 1.29;
    }
  }
}

@include break-point(mobile) {

}
