@import "../../style/mixins";

.pp-section-gallery {

  .title-block {
    margin-bottom: 48px;

    .title {
      text-align: center;
    }
  }

  .slider {
    .wrapper {
      height: 100%;

      img {
        height: 100%;
      }
    }
    .pagination-container{
      .swiper-pagination{
        width: auto;
      }
      span{
        right: 0;
        left: 0;
      }
    }
    &[dir='rtl'] {
      .control-arrow.single {
        transform: translateY(-50%);

        &.gallery-button-next {
          right: unset;
          left: 22px;
        }

        &.gallery-button-prev {
          left: unset;
          right: 22px;
        }

        & svg {
          left: 50%;
          transform: translate(-50%, -50%) rotate(180deg);
        }
      }
    }
  }

  .control-arrow {
    transform: translateY(-50%);

    &.single {
      &.reversed {
        left: 22px;
        right: unset;
        transform: translateY(-50%);
      }
    }
  }

  .gallery-button-next, .gallery-button-prev {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    box-shadow: 0 6px 12px rgba(45, 49, 54, 0.2);
  }

  .gallery-button-next {
    left: unset;
    right: 22px;
  }

  .gallery-button-prev {
    right: unset;
    left: 22px;
  }

  .swiper-button-disabled{
    cursor: unset;
    opacity: 0.3;
  }

  @include break-point(mobile) {
    .slider-thumb {
      display: none;
    }

    .slider {

      .slider-container {

        .swiper-wrapper {
          overflow: hidden;
        }
      }
    }

    &.pp-section-gallery-control {
      .slider.slider-gallery {
        margin-top: 40px;

        & .control-arrow,
        & .control-arrow.single {
          top: 103%;

          &.disabled {
            opacity: 0.2;
          }
        }

        & .gallery-button-next, .gallery-button-prev {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          box-shadow: 0 6px 12px rgba(45, 49, 54, 0.2);
        }

        & .control-arrow.single {
          transform: none;
          right: calc(49.5% - 32px);

          &.reversed {
            left: unset;
            transform: none;
            left: calc(49.5% - 32px);
          }
        }

        &[dir=rtl] {
          & .control-arrow.single,
          & .control-arrow.single.reversed {
            top: 103%;
          }

          & .control-arrow.single {
            transform: none;
            left: calc(49.5% - 32px);
            right: unset;

            & svg {
              transform: rotate(180deg) translate(50%, 50%);
            }

            &.reversed {
              left: unset;
              right: calc(49.5% - 32px);
              transform: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .title-block {
      &.center {
        text-align: left;
      }
    }

    &[dir=rtl] {
      .title-block {
        &.center {
          text-align: right;
        }
      }
    }
  }
}
