@import "../../style/mixins";

.pp-section-slider-img {
  padding-top: 144px;
  &.container {
    .pp-section-slider-img-container {
      padding-right: 0;
    }
  }
    &-container {
        padding-right: 360px;
    }
  .slider {
    margin-top: 8px;
    .swiper-slide {
      img {
        // height: calc(100vh - 100px);
        height: 600px;

      }
      .info-block {
        display: none;
      }
    }
  }
    .image {
        max-height: 1300px;
        overflow: hidden;
    }

  .info-block {
      padding: 0;
  }

  .controls {
    width: 90%;
    left: 5%;
  }
}
@media screen and (max-width: 1023px) {
  .pp-section-slider-img {
    &-container {
      padding-right: 54px;
  }
  .pagination .swiper-pagination {
    justify-content: right;
    display: flex;
  }
    .pagination {
      display: block;
    }
  }
}

@media screen and (max-width: 767px) {
  .pp-section-slider-img {
    .controls {
      width: 80%;
      left: 10%;
    }
  }
}

@include break-point(mobile) {
  .pp-section-slider-img {
    padding-top: 0;
    &-container {
      padding-right: 0px;
    }
    .slider {
      margin-top: 40px;
    }
  }
}
