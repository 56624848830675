@import "../../../style/mixins";

.bubble {
  display: inline-block;
  padding: 3px 12px;
  align-self: flex-start;
  border: 1px solid #002C5E;
  border-radius: 32px;
  @include text-simple;
  color: #002C5E;
}

.bubble_center {
  text-align: center;
}