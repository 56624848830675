@import "../../style/mixins";

.pp-section-footer {
  background-color: #1b1b1b;
  .oman{
    width: 50% !important;
  }
  .palestine{
    img{
      width: 120px;
    }
  }
  .logos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 810px;
    margin: 0 auto;

    .rivenditore {
      height: auto;
      max-width: 200px;

      &.jeddah, &.kuwait {
        max-width: 500px;
        width: 400px;

        @media (max-width: 1024px) {
          max-width: 300px;
        }
      }

      &.altsak {
        width: 110px;
      }
    }

    .logo {
      width: 186px;
      height: auto;
      margin-left: auto;
    }

    .image.dammam img {
      object-fit: contain;
    }
  }

  &.Jeddah {
    .logos {
      max-width: 1024px;
    }

    .pp-section-footer__dealers-content {
      column-gap: 50px;
    }
  }

  .pp-section-footer__dealers-content {
    @media (max-width: 600px) {
      flex-wrap: wrap;
      column-gap: 10px;
    }

    .pp-section-footer__tel {
      order: 2;
    }

    .social {
      order: 3;
    }
  }

  .dealer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .riyadh img {
      width: auto;
      height: 38px;
    }
    @media (max-width: 600px) {
      width: 100%;
      justify-content: center;
      margin-bottom: 20px;
    }
  }

  &__tel {
    width: 100px;
    margin: 0 16px;

    &--inline {
      width: auto;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: white;
      font-size: 24px;

      @media (max-width: 767px) {
        font-size: 18px;
      }

      @media (max-width: 500px) {
        font-size: 12px;
      }
    }

    &:hover .pp-section-footer__tel-text {
      opacity: 1;
    }
  }

  &__tel-text {
    opacity: 0.7;
    transition: opacity 0.3s ease;
  }

  &__dealers-content {
    display: flex;
    align-items: center;
    column-gap: 64px;
  }

  &__logo {
    width: 230px;
    flex-shrink: 0;
  }

  .text {
    @include text-small;
    color: #ffffff;
    text-align: center;
    max-width: 830px;
    margin: 24px auto 0;
    direction: ltr;
  }

  &[dir=rtl] {
    .logos {
      .logo {
        margin-left: 0;
        margin-right: auto;
      }
    }
  }
}


@include break-point(mobile) {
  .pp-section-footer {
    &__dealers-content {
      margin-bottom: 16px;
      justify-content: center;
      min-width: 50%;
      column-gap: 32px;
    }

    .dealer {
      img {
        object-fit: cover;
      }
    }

    .logos {
      flex-direction: column-reverse;
      align-items: center;

      .image {
        margin-top: 32px;

        &.jeddah {
          width: auto;
        }

        &:last-child {
          margin-top: 0;
        }
      }

      .rivenditore {
        &.altsak {
          width: 130px;
        }
      }

      .logo {
        margin-bottom: 24px;
        margin-left: 0;
      }
    }

    .text {
      font-size: 12px;
    }

    &[dir=rtl] {
      .logos {

        .logo {
          margin-right: 0;
        }
      }
    }
  }
}
