@import "./src/style/mixins";

.more {
  padding: 96px 0;
  overflow: hidden;
  background-color: #f6f3f2;

  p {
    text-align: center;
    @include text-small;
    font-size: 12px;
    color: #666;
    display: block;
    padding-bottom: 8px;
  }
}