@import "../../style/mixins";

.pp-section-hero {
  width: 100%;
  margin-top: 70px;
  &.hero-image {
    height: auto;
    img {
      height: calc(100vh - 100px);
    }
  }
  &.black > .container > .title-block > *,
  &.black * {
    color: black;
  }

  &.white > .container > .title-block > *,
  &.white * {
    color: white;
  }

  .container {
    padding: 48px 20px;
    @include positionX;
    top: 0;
    display: flex;
    height: 100%;
  }

  .title-block {
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: left;
    //direction: ltr;
    z-index: 2;
    //width: 100%;
    max-width: 1140px;
    margin-right: auto;


    .title {
      font-size: 58px;
      line-height: 64px;
    }

    .subtitle {
      font-size: 52px;
      line-height: 64px;
    }

    .btn-icon {
      margin-top: auto;
      align-self: flex-start;
    }
  }

  .image + .wrapper_video {
    display: none;
  }
  .wrapper_video {
    display: block;
    width: 100%;
    height: 100%;
  }

  .video {
    display: block;
    width: 100%;
    height: 135%;
    pointer-events: none !important;

  }

  &[dir="rtl"] {

    .title-block {
      text-align: right;
      margin-right: 0;
      margin-left: auto;

      .btn-icon {
        margin-left: auto;
        margin-top: 10px;
      }
    }
  }
}
@media screen and (max-width: 1023px) {
  .hero-image {
    height: auto;
    img {
      height: auto;
    }
  }
  // .pp-section-hero .wrapper_video {
  //   height: calc(100vh - 70px);
  // }
}

@media screen and (max-width: 767px) {
  .pp-section-hero {
    &.hero-image {
      height: auto;
      img {
        height: auto;
      }
    }
    .wrapper_video {
      height: calc(100vh - 60px);
    }
    .container {
      padding: 32px 20px;
      transform: none;
      left: 0;
    }

    // &.rtl {
      // .video {
        //left: 0;
        //transform: translateX(0);
      // }
    // }

    .video {
      display: block;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .title-block {

      .title {
        font-size: 32px;
        line-height: 36px;
      }

      .subtitle {
        font-size: 20px;
        line-height: 30px;
      }
    }

    &[dir="rtl"] {
      .title-block {
        margin-left: auto;
        margin-right: 0;
      }
    }
  }
}
