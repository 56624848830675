@import "../../../style/mixins";

.title-block {
  max-width: 800px;

  .title {
    @include title-h1;

    font-size: 50px;
    line-height: 56px;

    span {
      color: #00AAD2;
    }
  }

  .btn-icon {

    .icon {
      border-color: #fff;

      &:hover {
        border-color: $dark-color;

        svg {
          stroke: #fff;
        }
      }

      svg {
        transition: 0.1s;
        stroke: #fff;
        fill: #fff;

        &:hover {
          stroke: #FFFFFF;
        }
      }
    }

  }

  .subtitle {
    @include text-subtitle;
    margin-top: 16px;
    max-width: 760px;
  }

  &.white {
    .title,
    .subtitle {
      color: #ffffff;
    }
  }

  &.center {
    text-align: center;
    margin: 0 auto;
  }
}

.centered .title-block {
  margin: 0 auto;
  text-align: center;
}

@include break-point(mobile) {

  .title-block {

    .subtitle {
      margin-top: 8px;
    }

    .btn-icon {
      margin-top: 16px;
    }
  }

}
